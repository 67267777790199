<template>
  <div>
      <b-overlay :show="loading">
      <card/>
      <body-card>
          <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('teaGardenBtriService.certificate_issue') }}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button type="button" variant="primary" class="btn btn-add btn-success mr-2" @click="pdfExport"><i class="fas fa-print"></i> {{ $t('globalTrans.print') }}</b-button>
          </template>
          <div class="p-2">
              <div class="certificate">
                  <img :src="trainingElearningServiceBaseUrl+'storage/'+formData.right_logo" alt="" style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 200px;height: 200px;opacity: 0.25;">
                  <div class="certificate-inner">
                      <div style="border: 2px solid #ff9a9a; padding: 1rem;">
                          <div class="content text-center">
                              <div class="logo">
                                  <div class="logo-wrapper">
                                      <img style="width:80px; height:70px;" :src="trainingElearningServiceBaseUrl+'storage/'+formData.right_logo">
                                  </div>
                              </div>
                              <div class="org">
                                  <p style="font-family: certificate; font-size: 1.7rem; font-weight: 600; font-style: italic; color:blue;">{{ getOrg(formData.org_id) }}</p>
                              </div>
                              <div style="margin: 1rem 0;"></div>
                              <div style="width: 70%; border-bottom: 1px solid; margin: 1rem 0;">
                                  <p style="font-size: 1.0rem; margin: 1rem 0; font-weight: 500;">awards this certificate to</p>
                                  <p style="font-size: 1.5rem; font-weight: 500; font-style: italic; color:blue;">{{ currentLocale === 'bn' ? formData.name_bn : formData.name }}</p>
                              </div>
                              <p style="font-weight: 500; font-size: 1.0rem;">in recognition of her successful participation in the training on training on</p>
                              <br>
                              <p style="font-weight: 500; font-size: 1.3rem; ">“{{ currentLocale === 'bn' ? formData.training_title_bn : formData.training_title_en }}”.</p>
                              <p style="font-weight: 500; margin: 1rem 0; font-size: 1rem;">{{ date }}</p>
                              <div style="margin: 0.5rem 0;"></div>
                          </div>
                          <table style="width: 100%; margin-top: 1rem;">
                              <tr>
                                  <td style="text-align: left;">
                                      <div style="text-align: center; display: inline-block;">
                                          <p>{{ getUserName(form.receiver_id) }},</p>
                                          <p>{{ getDesignation(form.designation_id) }}, </p>
                                          <p>{{ getOrg(form.org_id) }} </p>
                                          <p v-if="form.course_designation_bn !== '' && form.course_designation !== ''">& {{ currentLocale === 'bn' ? form.course_designation_bn : form.course_designation }}</p>
                                      </div>
                                  </td>
                                  <td style="text-align: right;">
                                      <div style="text-align: center; display: inline-block;">
                                          <p>{{ getUserName(form.second_receiver_id) }},</p>
                                          <p>{{ getDesignation(form.second_designation_id) }}, </p>
                                          <p>{{ getOrg(form.org_id) }} </p>
                                          <p v-if="form.second_course_designation_bn !== '' && form.second_course_designation !== ''">& {{ currentLocale === 'bn' ? form.second_course_designation_bn : form.second_course_designation }}</p>
                                      </div>
                                  </td>
                              </tr>
                          </table>
                          <br>
                      </div>
                  </div>
              </div>
          </div>
  </body-card>
  </b-overlay>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { certificateIndividualShow, certificateIndividualPdf } from '../../api/routes'

export default {
    props: ['id'],
    created () {
          this.loadData()
    },
    mounted () {
    },
    data () {
        return {
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {},
            form: {},
            date: '',
            isLoading: false,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
            loading: false,
            userInfo: {},
            users: []
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        designationList () {
            return this.$store.state.commonObj.designationList.filter(item => item.status === 1)
        }
    },
    watch: {
    },
    methods: {
      //   getFormData () {
      //       const tmpData = this.$store.state.list.find(item => item.id === this.$route.query.id)
      //       return JSON.parse(JSON.stringify(tmpData))
      //   },
        async pdfExport () {
            this.unitLoading = true
            const params = Object.assign({}, { request_type: 'pdf', id: this.formData.id, local: this.$i18n.locale, org_id: 12, date: this.date })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, certificateIndividualPdf, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            this.unitLoading = false
        },
        async loadData () {
          this.loading = true
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const params = {
              id: this.$route.query.id
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, certificateIndividualShow, params)
          if (!result.success) {
          } else {
              this.formData = result.data
              this.form = result.certificateMain
              var ids = []
              ids.push(this.form.receiver_id)
              ids.push(this.form.second_receiver_id)
              this.getAllUser(ids)
          }
          this.getDate()
          this.loading = false
      },
      getOrg (orgid) {
            const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(item => item.value === orgid)
            if (orgObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return orgObj.text_bn
                } else {
                    return orgObj.text_en
                }
            }
          },
      getDesignation (orgid) {
            const desigObj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === orgid)
            if (desigObj !== undefined) {
                if (this.$i18n.locale === 'bn') {
                    return desigObj.text_bn
                } else {
                    return desigObj.text_en
                }
            }
          },
      getUserName (userId) {
            const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
            return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
        },
      async getAllUser (ids) {
            const params = Object.assign({}, { user_ids: ids })
            await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
                if (response.length) {
                    this.users = response
                } else {
                    this.users = []
                }
            })
        },
      getDate () {
              const startDate = this.formData.training_start_date.split('-')
              const endDate = this.formData.training_end_date.split('-')
              let startMonthId = ''
              let endMonthId = ''
              startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
              endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
          if (startDate[1] === endDate[1]) {
                this.date = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          } else {
                this.date = this.$n(startDate[2]) + ' ' + (this.currentLocale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.currentLocale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ',' + this.$n(startDate[0], { useGrouping: false })
            }
      }
    }
}
</script>
<style>
.certificate{
    padding: 2rem;
    position: relative;
    /* background-image: url(../../../../../assets/images/bd-foreign-trade-icon.png); */
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}
.certificate-inner{
    padding: .4rem;
    border: 5px solid #0ca99a;
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
}
.certificate-inner .content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.certificate-inner .content .logo .logo-wrapper{
    width: 100%;
    max-width: 130px;
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
}
.certificate-inner .content .logo .logo-wrapper p{
  text-align: left;
}
</style>
